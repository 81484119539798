// Zero
$screen-xs-min: 0;
// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;
// Small tablets (portrait view)
$screen-md-min: 768px;
// Tablets and small desktops
$screen-lg-min: 992px;
// Large tablets and desktops
$screen-xl-min: 1200px;
// semi HD desktops
$screen-shd-min: 1560px;
// HD desktops
$screen-fhd-min: 1900px;

// Small devices
@mixin xs {
  @media (min-width: #{$screen-xs-min}) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Semi HD devices
@mixin shd {
  @media (min-width: #{$screen-shd-min}) {
    @content;
  }
}

// Full HD devices
@mixin fhd {
  @media (min-width: #{$screen-fhd-min}) {
    @content;
  }
}

html,
body {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: #bababa;
}

body.admin{
  background-color: #2a2e43;
}

body {
  // background-image: url(../images/PP54080507-Tropical-Blue-Ocean-Water.jpg);
  // background-color: rgb(38, 44, 73);
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.topSection {
  background-color: #34384c;
  height: 80px;
  .portalLogo {
    .logo {
      height: 80px;
      padding: 10px;
    }
  }
  .landingLogin {
    display: block;
    color: #b6c0ef;
    float: right;
    padding: 10px 20px;
    background-color: rgba(42, 46, 67, 1);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    text-decoration: none;
  }
  .landingLogin:hover {
    display: block;
    color: #b6c0ef;
    float: right;
    padding: 10px 20px;
    background-color: #484d64;
  }
}

.admin .topSection{
  background: none;
}

.menuSection {
  position: relative;
  background-color: rgba(42, 46, 67, 1);
  z-index: 1;
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      float: left;
      a {
        padding: 10px 20px;
        display: block;
        color: #bababa;
        text-decoration: none;
      }
      a:hover {
        color: #cccccc;
        background-color: rgba(255, 255, 255, 0.1);
      }
      a.active {
        color: #ffffff;
        background-color: #4a5177;
      }
    }
  }

  .searchContainer{
    width: 100%;
    height: 100%;
    .searchMenuBtn{
      position: absolute;
      top: 0;
      right: 15px;
      background: none;
      border: none;
      color: #b6c0ef;
      padding: 10px;
      outline: none;
      cursor: pointer;
    }
    .searchMenuInput{
      background-color: rgba(0, 0, 0, 0.2);
      border: none;
      height: 100%;
      outline: none;
      color: #b6c0ef;
      width:0%;
      padding-left: 0;
      opacity: 0;
      padding-right: 32px;
      overflow:hidden;
      -webkit-transition: all 1s ease-in-out;
      -moz-transition: all 1s ease-in-out;
      -o-transition: all 1s ease-in-out;
      transition: all 1s ease-in-out;
    }
  }
  .searchContainer:hover{
    .searchMenuInput{
      width: 100%;
      padding-left: 12px;
      opacity: 1;
    }
  }
}

.bannerSection{
  .banner{
    background-image: url(../images/banner1.png);
    background-position: center;
    background-color: rgba(42, 46, 67, 0.9);
    background-size: 100%;
    width: 100%;
  }
}

.featureSection{
  .featureItemPanel{
    background: #FFFFFF;
    box-shadow: 0 2px 4px #bababa;
    border-radius: 8px;
    overflow: hidden;
    .featureItemImage{
      width: 100%;
    }
    .icon-wrd-badge{
      color: #ffffff;
      font-size: 24px;
      background-color: #34384c;
      padding: 10px;
      border-radius: 50px;
      float: left;
      margin-right: 10px;
    }
    .headline{
      color: #34384c;
      margin-bottom: 0;
    }
    .tag{
      color: orange;
    }
    .featureLink{
      width: 100%; 
      text-align: right;
      display: block;
    }
  }
}

.newsSection{
  height: auto;
  position: relative;
  .newsBanner{
    width: 100%;
    position: relative;
    background-size: cover;
    .textContainer{
      position: absolute;
      bottom: 0;
      left: 0;
      padding-top: 20px;
      background: rgb(0,0,0);
      background: -moz-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.8) 50%, rgba(0,0,0,0) 100%);
      background: -webkit-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.8) 50%, rgba(0,0,0,0) 100%);
      background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.8) 50%, rgba(0,0,0,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
      h2, p{
        text-shadow:0 0 4px #666;
      }
      ul{
        position: absolute;
        bottom: 10px;
        right: 20px;
        list-style-type: none;
        li{
          float: right;
          button{
            background: none;
            border: none;
            color: #bababa;
            span{
              color: #b6c0ef;
              float: left;
              margin-left: 10px;
              margin-top: 4px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
  .newsBanner1{
    background-image: url(/images/maptitude-flood-map-world.jpg);
    background-position: bottom center;
    height: 350px;
    @include xl{
      height: 700px;
    }
  }
  .newsBanner2{
    background-image: url(/images/Scientist.jpg);
    background-position: bottom right;
    height: 350px;
    @include xl{
      height: 700px;
    }
  }
  .newsBanner3{
    background-image: url(/images/water-pur.jpg);
    background-position: bottom right;
    height: 350px;
  }
  .newsBanner4{
    background-image: url(/images/DHI-Confrence.jpg);
    background-position: bottom right;
    height: 350px;
  }
}

.contentSection{
  .latestNews{
    .head{
      font-size: 24px;
      background: #bababa;
      color: #666;
      span{
        color: #c8ff00;
        font-weight: bold;
      }
    }
    .body{
      background: #cccccc;
      ul{
        list-style-type: none;
        li{
          padding: 10px 0;
          position: relative;
          border-bottom: 1px dotted #999;
          .latestNewsThumb{
            width: 100px;
            height: 70px;
            float: left;
            margin-right: 10px;
          }
              h3{
            color: #25589b;
            font-size: 14px;
            margin-bottom: 0
          }
          p{
            color: #999;
            font-size: 14px;
            font-weight: normal;
            line-height: 14px;
          }
          .latestNewsLink{
            position: absolute;
            bottom: 5px;
            right: 0;
          }
        }
        li:last-child{
          border: none;
        }
      }
      
    }
    .footer{
      background: #ddd;
      color: #666;
      text-align: center;
      padding: 10px;
    }
  }
}

.admin .contentSection{
  height: calc(100% - 114px);
  display: block;
  background: none;
}

.footerSection{
  background: #2a2e43;
  .title{
    text-transform: uppercase;
    font-weight: 500;
    color: white;
    margin-bottom: 15px;
  }
  ul{
    margin: 0;
    padding: 0;
    list-style-type: none;
    li{
      a{
        color: #b6c0ef;
        display: block;
      }
      a:hover{
        color: orange;
      }
      
    }
  }
  .emSubInput{
    background: none;
    border: 1px solid #b6c0ef;
    padding: 5px 8px;
    border-radius: 4px;
    color: #b6c0ef;
    width: 50%;
  }

  .emSubBtn{
    background: #b6c0ef;
    border: none;
    outline: none;
    border-radius: 4px;
    padding: 5px 8px;
    margin-left: 5px;
  }
}

.copySection{
  background: #202231;
  .text{
    font-size: 12px;
    margin: 0;
    padding: 8px;
  }
}

.admin .copySection{
  background: none;
}

.red{ background: red }
.green{ background: green }
.blue{ background: blue }
.yellow{ background: yellow }

.panel-apps-white {
  position: absolute;
  width: 350px;
  right: 5px;
  background: rgba(255, 255, 255, 0.95);
  z-index: 2;
  height: 500px;
  border-radius: 5px;
  margin: 10px 5px;
  overflow: auto;
}

.link-apps-white {
  display: flex;
  width: 100%;
  text-align: left;
  color: #0b4566;
  align-items: center;
}

.list-apps-white {
  background: #f8f8f8;
  border-radius: 5px;
  margin: 5px;
}

.list-apps-white:hover{
  background:#007bff;
  color: #ffffff;
}

.apps-selected-white {
  background:#007bff;
  color: #ffffff !important;
}

.link-apps-white:hover {
  color: #ffffff;
}

.panel-apps-default {
  position: absolute;
  width: 350px;
  right: 5px;
  background: rgba(42, 46, 67, 0.95);
  z-index: 2;
  height: 500px;
  border-radius: 5px;
  margin: 10px 5px;
  overflow: auto;
}

.link-apps-default {
  width: 100%;
  text-align: left;
  color: #8891be;
  display: flex;
  align-items: center;
}

.list-apps-default {
  background: #353b5a;
  color: #8891be;
  border-radius: 5px;
  margin: 5px;
}

.list-apps-default:hover{
  background:#434d7e;
  color: #ffffff;
}

.link-apps-default:hover {
  color: #ffffff;
}

.apps-selected-default {
  background:#434d7e;
  color: #ffffff;
}

.appName {
  font-size:15px !important;
  white-space: normal;
  padding:5px 0px;
}

.logo-apps {
  width: 45px;
  height:45px;
  padding: 5px;
  margin-right: 10px;
}
