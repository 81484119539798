.head-sort-span {
    margin-left: 10px;
    font-size: 11px;
    color: #8891be;
}

.grid-no-sort {
    cursor: default;
}

.grid-sort {
    cursor: pointer;
}
