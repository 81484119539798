@font-face {
  font-family: 'icomoon';
  src:  url('./fonts/icomoon.eot?h27y7m');
  src:  url('./fonts/icomoon.eot?h27y7m#iefix') format('embedded-opentype'),
    url('./fonts/icomoon.ttf?h27y7m') format('truetype'),
    url('./fonts/icomoon.woff?h27y7m') format('woff'),
    url('./fonts/icomoon.svg?h27y7m#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-wrd-up:before {
  content: "\e970";
}
.icon-wrd-launch:before {
  content: "\e952";
}
.icon-wrd-check-circle:before {
  content: "\e94f";
}
.icon-wrd-rotate_left:before {
  content: "\e939";
}
.icon-wrd-rotate_right:before {
  content: "\e93a";
}
.icon-wrd-identify:before {
  content: "\e93b";
}
.icon-wrd-themes:before {
  content: "\e936";
}
.icon-wrd-flag:before {
  content: "\e96b";
}
.icon-wrd-mouse-pointer:before {
  content: "\e9a6";
}
.icon-wrd-sync:before {
  content: "\e627";
}
.icon-wrd-shapes:before {
  content: "\e25e";
}
.icon-wrd-select:before {
  content: "\e3ec";
}
.icon-wrd-star:before {
  content: "\e83a";
}
.icon-wrd-folder:before {
  content: "\e911";
}
.icon-wrd-edit:before {
  content: "\e910";
}
.icon-wrd-close:before {
  content: "\e90f";
}
.icon-wrd-search:before {
  content: "\e90d";
}
.icon-wrd-add:before {
  content: "\e9db";
}
.icon-wrd-users:before {
  content: "\e913";
}
.icon-wrd-tune:before {
  content: "\e429";
}
.icon-wrd-alert-triangle:before {
  content: "\e92e";
}
.icon-wrd-chevrons-right:before {
  content: "\e95e";
}
.icon-wrd-download:before {
  content: "\e97f";
}
.icon-wrd-external-link:before {
  content: "\e985";
}
.icon-wrd-folder-minus1:before {
  content: "\e993";
}
.icon-wrd-folder-plus:before {
  content: "\e994";
}
.icon-wrd-layers:before {
  content: "\e9a9";
}
.icon-wrd-link:before {
  content: "\e9ac";
}
.icon-wrd-lock:before {
  content: "\e9b1";
}
.icon-wrd-minimize-arrow:before {
  content: "\e9bf";
}
.icon-wrd-more:before {
  content: "\e9c6";
}
.icon-wrd-reset:before {
  content: "\e9e7";
}
.icon-wrd-settings:before {
  content: "\e9ee";
}
.icon-wrd-unlock:before {
  content: "\ea18";
}
.icon-wrd-upload:before {
  content: "\ea1a";
}
.icon-wrd-power:before {
  content: "\e8ac";
}
.icon-wrd-profile:before {
  content: "\e853";
}
.icon-wrd-chevrons-left:before {
  content: "\e95d";
}
.icon-wrd-sidebar:before {
  content: "\e9f6";
}
.icon-wrd-binders:before {
  content: "\e96f";
}
.icon-wrd-key-file:before {
  content: "\e96e";
}
.icon-wrd-key-spreadsheet:before {
  content: "\e96a";
}
.icon-key-file-picture:before {
  content: "\e96c";
}
.icon-wrd-key-data-link:before {
  content: "\e96d";
}
.icon-wrd-data-link:before {
  content: "\e968";
}
.icon-wrd-file:before {
  content: "\e950";
}
.icon-wrd-public:before {
  content: "\e942";
}
.icon-wrd-screen:before {
  content: "\e933";
}
.icon-wrd-portal:before {
  content: "\f2b0";
}
.icon-wrd-image:before {
  content: "\f03e";
}
.icon-wrd-contact:before {
  content: "\f2bc";
}
.icon-wrd-registration:before {
  content: "\f1da";
}
.icon-wrd-building:before {
  content: "\f0f8";
}
.icon-wrd-revoke:before {
  content: "\f05c";
}
.icon-wrd-grant:before {
  content: "\f05d";
}
.icon-wrd-simulation-model:before {
  content: "\e967";
}
.icon-wrd-normal-rasters:before {
  content: "\e965";
}
.icon-wrd-temporal-rasters:before {
  content: "\e966";
}
.icon-wrd-table-result:before {
  content: "\e964";
}
.icon-wrd-copy:before {
  content: "\e963";
}
.icon-wrd-spreadsheet:before {
  content: "\e962";
}
.icon-wrd-triangle:before {
  content: "\e961";
}
.icon-wrd-dhi-list-view:before {
  content: "\e95f";
}
.icon-wrd-dhi-tile-view:before {
  content: "\e960";
}
.icon-wrd-data-coverage:before {
  content: "\e95c";
}
.icon-wrd-dhi-gis-layer:before {
  content: "\e958";
}
.icon-wrd-dhi-menu:before {
  content: "\e959";
}
.icon-wrd-dhi-url:before {
  content: "\e95a";
}
.icon-wrd-satelite:before {
  content: "\e95b";
}
.icon-wrd-maxmize-arrow:before {
  content: "\e957";
}
.icon-wrd-home:before {
  content: "\e956";
}
.icon-wrd-bottom-left-resize:before {
  content: "\e953";
}
.icon-wrd-top-left-resize:before {
  content: "\e954";
}
.icon-wrd-top-right-resize:before {
  content: "\e955";
}
.icon-wrd-edit-gis-symbology:before {
  content: "\e951";
}
.icon-wrd-line-type-solid:before {
  content: "\e945";
}
.icon-wrd-line-type-dash:before {
  content: "\e94d";
}
.icon-wrd-line-type-dot:before {
  content: "\e94e";
}
.icon-wrd-ts-symbology:before {
  content: "\e947";
}
.icon-wrd-type-square:before {
  content: "\e948";
}
.icon-wrd-type-circle:before {
  content: "\e949";
}
.icon-wrd-type-cross:before {
  content: "\e94a";
}
.icon-wrd-type-star:before {
  content: "\e94b";
}
.icon-wrd-type-diamon:before {
  content: "\e94c";
}
.icon-wrd-plot-statistic:before {
  content: "\e946";
}
.icon-wrd-lock-geometry:before {
  content: "\e943";
}
.icon-wrd-view-geometry:before {
  content: "\e944";
}
.icon-wrd-gis-label:before {
  content: "\e941";
}
.icon-wrd-drag:before {
  content: "\e940";
}
.icon-wrd-bottom-right-resize:before {
  content: "\e93c";
}
.icon-wrd-zoom-full-extend:before {
  content: "\e93d";
}
.icon-wrd-zoom-next:before {
  content: "\e93e";
}
.icon-wrd-zoom-previous:before {
  content: "\e93f";
}
.icon-wrd-disconnect:before {
  content: "\e935";
}
.icon-wrd-disabled-app:before {
  content: "\e934";
}
.icon-wrd-log-in:before {
  content: "\e932";
}
.icon-wrd-polygon:before {
  content: "\e92d";
}
.icon-wrd-polyline:before {
  content: "\e92f";
}
.icon-wrd-polypoint:before {
  content: "\e930";
}
.icon-wrd-arrow-down:before {
  content: "\e92b";
}
.icon-wrd-arrow-up:before {
  content: "\e92c";
}
.icon-wrd-move:before {
  content: "\e92a";
}
.icon-wrd-chevrons-down:before {
  content: "\e929";
}
.icon-wrd-app-menu:before {
  content: "\e928";
}
.icon-wrd-bell:before {
  content: "\e920";
}
.icon-wrd-arrow-left-circle:before {
  content: "\e926";
}
.icon-wrd-arrow-right-circle:before {
  content: "\e927";
}
.icon-wrd-minimize-box:before {
  content: "\e900";
}
.icon-wrd-refresh:before {
  content: "\e924";
}
.icon-wrd-timeseries:before {
  content: "\e901";
}
.icon-wrd-info:before {
  content: "\e922";
}
.icon-wrd-box:before {
  content: "\e91f";
}
.icon-wrd-map:before {
  content: "\e921";
}
.icon-wrd-user-confirm:before {
  content: "\e91d";
}
.icon-wrd-badge:before {
  content: "\e91c";
}
.icon-wrd-add-users:before {
  content: "\e91b";
}
.icon-wrd-share:before {
  content: "\e902";
}
.icon-wrd-bookmark:before {
  content: "\e916";
}
.icon-wrd-briefcase:before {
  content: "\e917";
}
.icon-wrd-phone:before {
  content: "\e918";
}
.icon-wrd-sorting-up:before {
  content: "\e915";
}
.icon-wrd-sorting-down:before {
  content: "\e903";
}
.icon-wrd-eye-off:before {
  content: "\e904";
}
.icon-wrd-calendar:before {
  content: "\e907";
}
.icon-wrd-mobile-menu:before {
  content: "\e90c";
}
.icon-wrd-eye:before {
  content: "\e905";
}
.icon-wrd-mail-closed:before {
  content: "\e906";
}
.icon-wrd-alert-circle:before {
  content: "\e90a";
}
.icon-wrd-trash:before {
  content: "\e90b";
}
.icon-wrd-applications:before {
  content: "\e908";
}
.icon-wrd-user-detail:before {
  content: "\e909";
}
.icon-wrd-workspace:before {
  content: "\e919";
}
.icon-file-picture:before {
  content: "\e969";
}
.icon-wrd-globe:before {
  content: "\e9c9";
}
.icon-wrd-checkmark:before {
  content: "\e91a";
}
.icon-wrd-ws-list:before {
  content: "\e912";
}
.icon-wrd-arrow-right:before {
  content: "\e90e";
}
.icon-wrd-arrow-left:before {
  content: "\e923";
}
.icon-wrd-sortings:before {
  content: "\e925";
}
.icon-wrd-file-pdf:before {
  content: "\e937";
}
.icon-wrd-file-css:before {
  content: "\e938";
}
.icon-wrd-page-error:before {
  content: "\e931";
}
.icon-wrd-news:before {
  content: "\e91e";
}
.icon-wrd-docfile-pdf:before {
  content: "\e914";
}
