/* LAYOUT */
.width-50 {
  width: 50% !important;
}

.width-100px {
  width: 100px;
}

/*WORKSPACE DETAIL*/

.status-enabled {
  color: #3eb22a;
}

.status-pending {
  color: #ffbb00;
}

.status-disabled {
  color: #ff3333;
}

.status-disabled-important {
  color: #ff3333 !important;
}

.update-button {
  width: 85%;
  padding: 13px;
  border-radius: 8px;
}

/*GRID*/

.head-sort-span {
  padding-left: 10px;
}

.grid-sort {
  cursor: pointer;
  padding-left: 10px;
}

.hide-action {
  display: none !important;
}

.show-action {
  display: block !important;
}

.sticky-action-enable {
  color: #abff00 !important;
  font-size: 14px !important;
  cursor: pointer;
}

.sticky-action-disable {
  color: #ff3333 !important;
  font-size: 14px !important;
  cursor: pointer;
}

/* GRID SETTINGS */

.grid-item {
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 0;
}

.tableWrap .table-responsive .gridSettings table th {
  text-align: left !important;
  height: 50px !important;
}

.tableWrap .table-responsive .gridSettings table tbody tr td {
  text-align: left !important;
}

.tableWrap .table-responsive .gridSettings table tbody tr td {
  height: 50px !important;
}

/*SPINNER*/
.loader-container {
  z-index: 99999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader-container-workspace-connections {
  z-index: 99999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/*FILE UPLOADER*/

input[type="file"] {
  display: none;
}

.custom-file-upload {
  display: inline-block;
  cursor: pointer;
  margin-bottom: 0px;
}

.icon-upload-folder {
  font-size: 26px;
}

.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 100px;
}

.crop-controls {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 98%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
}

.crop-components {
  width: 50%;
  padding: 10px;
  margin-left: 10px;
}

.crop-form-control {
  padding-left: 15px;
  width: 50%;
}

.slider {
  width: 200px;
  height: 10px;
}

.crop-button {
  margin-left: 15px;
}

.crop-welcome1820 {
  top: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
}

.welcome-div-index {
  z-index: 1;
}

.welcome-text-pos {
  position: sticky;
  top: 10%;
  left: 8%;
  right: 10%;
}

.crop-logo11 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 15%;
}

.crop-logo32 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 25%;
}

.crop-logo43 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 25%;
}

.crop-logo169 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 30%;
}

.crop-logo219 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 37%;
}

.crop-photo11 {
  width: 80%;
  border-radius: 8px;
  border: 10px solid #494c5e;
}

.crop-photo32 {
  width: 80%;
  border-radius: 8px;
  border: 10px solid #494c5e;
}

.crop-photo43 {
  width: 80%;
  border-radius: 8px;
  border: 10px solid #494c5e;
}

.crop-photo169 {
  width: 80%;
  border-radius: 8px;
  border: 10px solid #494c5e;
}

.crop-photo219 {
  width: 80%;
  border-radius: 8px;
  border: 10px solid #494c5e;
}

.crop-banner32 {
  width: 100%;
}

.crop-feature11 {
  width: 65%;
}

.crop-feature32 {
  width: 100%;
}

.crop-feature43 {
  width: 87%;
}

.crop-feature169 {
  width: 113%;
}

.crop-feature219 {
  width: 149%;
}

.featureLandingWidth {
  width: 100%;
  height: 100%;
}

/* RICH TEXT EDITOR */

.note-current-fontname {
  font-size: 18px !important;
}

.note-icon-caret {
  font-size: 20px !important;
}

/* APPLICATION LIST */
.main-application-list-body {
  height: 150px;
}

/* APPLICATION DETAIL*/
.icon-lock {
  color: #ff3333 !important;
}

.icon-unlock {
  color: #3ac24a !important;
}

/* IMAGE */
.workspace-app-image {
  width: 60px;
  padding: 5px;
}

/* USER DETAIL */
.hide-registration-status {
  display: none;
}

.label-user-detail {
  color: #999999;
  font-size: 14px;
}

.label-status {
  padding-left: 10px;
}

/* LINK */
.link-disabled {
  cursor: default;
}

/* GRID PAGINATION */
.grid-pagination {
  margin-bottom: 15px;
}

/* PORTAL LOGO */
.sidebar-header-logo {
  width: 80%;
}

.sidebar-header-logo-none {
  width: 80%;
  display: none;
}

.portal-logo11 {
  width: auto;
  padding-top: 5%;
}

.portal-logo32 {
  width: auto;
  padding-top: 10%;
}

.portal-logo43 {
  width: auto;
  padding-top: 8%;
}

.portal-logo169 {
  width: auto;
  padding-top: 10%;
}

.portal-logo219 {
  width: 80%;
  padding-top: 15%;
}

.loader-container-sidebar-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  position: absolute;
  top: 6.5%;
  left: 3%;
}

.loader-container-portal-login-setting {
  z-index: 99999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader-container-portal-email-setting {
  z-index: 99999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-option-logo {
  width: 214.56px !important;
  height: 120.16px;
}

/* CURSOR */
.cursor-pointer {
  cursor: pointer;
}

/* WORKSPACE APPLICATIONS SETTINGS */
.settings-group-form {
  padding-left: 10px !important;
}

.settings-title {
  padding-left: 15px !important;
}

/* AVAILABLE WORKSPACES */

.wsListOwner {
  color: orange;
}

.wsListSuspend {
  color: #ff3333;
  font-weight: bold;
}

.searchCustomButton {
  position: absolute;
  bottom: 5px;
}

.btnSwitchRole {
  position: fixed;
  padding: 8px 15px;
  background-color: #2a2e43;
  border: 2px solid #8891be;
  border-radius: 50px;
  color: #8891be;
  font-family: "robotoBold";
  top: 15px;
  right: 200px;
}

.btnSwitchRole:hover {
  color: #ffffff;
  border-color: #ffffff;
}

.loader-available-workspace-list {
  z-index: 99999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* PORTAL LANDING */
.portal-banner-title {
  position: absolute;
  top: 80px;
  left: 210px;
  font-family: Arial, Helvetica, sans-serif;
  display: none;
}

.portal-banner-caption {
  position: absolute;
  top: 125px;
  left: 140px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  display: none;
}

.loader-portal-banner {
  z-index: 99999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader-portal-feature {
  z-index: 99999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader-portal-news {
  z-index: 99999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.available-workspaces-pagination {
  position: relative;
  margin-top: 25px;
}

.browse-new-workspace-banner-setting {
  left: 500px;
  bottom: 35px;
}

/* WORKSPACES APPLICATIONS LANDING */
.workspaces-application-container {
  height: calc(100vh - 60px);
  border-top: solid 2px #efefef;
  border-left: none;
  border-bottom: none;
  border-right: none;
}

.workspaces-application-not-available {
  height: calc(100vh - 60px);
  width: calc(100vw - 450px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.workspaces-application-icon-error {
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 180px;
}

.workspaces-application-text-error {
  font-size: 20px;
}

/*Login Password Label Validation*/
.password-validation {
  position: absolute;
  left: 10px;
  color: orangered;
}

/*Modal Connection Max Height*/
.modal-connection-height {
  max-height: 500px;
  overflow-y: auto;
}

.wrap-text-workspace-landing {
  word-wrap: break-word;
  white-space: normal !important;
}

.defaultCursorSidebarLogo {
  cursor: default !important;
}

/*Radio button*/
.custom-controls-knob {
  padding-top: 35px !important;
}

.custom-controls-left-padding {
  padding-left: 50px !important;
}

.custom-control-radio {
  padding-bottom: 15px !important;
  padding-left: 5.5rem !important;
}

.custom-control-radio-create {
  padding-bottom: 10px !important;
  padding-left: 1.5rem !important;
}

.label-focus-area-create-workspace {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 16px !important;
}

.wsNameEditWider {
  width: 400px;
}

.appNameEditWider {
  width: 768px;
}

.wsAppNameEditWider {
  width: 400px;
}

.modal-backdrop,
.modal-backdrop.in {
  display: none;
}

.summernote [type="file"] {
  display: block;
}

/* Focus Area */
.focus-area-selected > tr {
  background-color: #4e82c7 !important;
}

.focus-area-selected > td {
  background-color: #4e82c7 !important;
}

.focus-area-no-geometry {
  color: #ff3333 !important;
  font-size: 24px !important;
}

.workspace-landing-title-text {
  font-size: 20px !important;
}

.scroll-Share-Workspaces-List {
  overflow-y: scroll !important;
  height: 470px !important;
}

.isDisabled {
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

.isDisabledNormal {
  cursor: default;
  opacity: 0.5;
  text-decoration: none;
}

.tableWrap.focusAreaList
  .table-responsive
  table
  tbody
  tr
  td
  .action
  li:last-child
  a:hover {
  color: #999999;
}

.tableWrap.focusAreaList
  .table-responsive
  table
  tbody
  tr
  td
  .action
  li:last-child
  a:hover
  span {
  color: #999999;
}

.browse-shapefile-box {
  color: #0c5460;
  background-color: #999999;
  border-color: #bee5eb;
}

.text-align-center_portal {
  text-align: center;
}

.table-color {
  color: #bababa;
}

.table-min-width {
  min-width: 1500px !important;
}

.button-disabled {
  background-color: #5c5e6e;
  color: #494c5e;
  cursor: not-allowed;
}

.inactive-lock-label {
  color: #ff0000 !important;
  right: 240px !important;
  left: 0px !important;
}

.table-workspace-member {
  width: 93% !important;
}

.no-background {
  background-image: none;
  background-position: bottom center;
  height: 100%;
  text-align: center;
}

.news-height {
  height: 100%;
}

/* WORKSPACE LANDING PAGE */
.cancel-main-margin {
  margin-left: 0px !important;
}

.mainhead-margin-top {
  padding-top: 0px !important;
}

.margin-top-8rem {
  margin-top: 8rem;
}

.header-workspace-landing {
    margin-top: 100px;
}

.mainbody-margin-top {
  margin-top: 70px !important;
}

.close-x-list {
  position: fixed;
  top: 25px;
  right: 20px;
}

.button-profile {
  border: none;
  padding: 7px;
  background: none;
}

.button-login {
  border: none;
  padding: 8px;
  background: none;
}

.userProfile-default {
  border-radius: 20px;
  padding: 6px;
  background: #8891be;
  color: #2a2e43;
  font-size: 15px !important;
}

.userProfile-white {
  border-radius: 20px;
  padding: 6px;
  background: #0b4566;
  color: #f8f8f8;
  font-size: 15px !important;
}

.workspaceBackgroundNoImage {
  position: absolute;
  top: 48px;
  left: 0;
  height: 320px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.font-style-wsname-zoom {
  font-family: 'Roboto' !important;
  font-size: 32px;
  font-weight: 700;
  font-style: normal;
  color: #FFFFFF;
  margin-bottom: 0rem !important;
}

.font-style-wstitle-zoom {
  font-family: 'Roboto' !important;
  font-size: 24px;
  font-weight: 400;
  color: #FFFFFF;
}

.font-style-wsdeescription-zoom {
  font-family: 'Roboto' !important;
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF;
}

.font-style-wsapptitle-zoom {
  font-family: 'Roboto' !important;
  font-size: 32px;
}

.font-style-wsbutton-zoom {
  font-family: 'Roboto' !important;
  font-size: 14px;
}

.font-style-wscategdesc-zoom {
  font-family: 'Roboto' !important;
  font-size: 18px;
}

.font-style-wsappdesc-zoom {
  font-family: 'Roboto' !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.005em;
  color: #0B4566;
}

.font-style-wslink-zoom {
  font-family: 'Roboto' !important;
  font-size: 32px;
  font-style: normal;
  font-weight: bold;
  color: #FFFFFF;
}

.font-style-wsedit-zoom {
  font-family: 'Roboto' !important;
  font-size: 12px;
}

.font-style-bottomtext-zoom {
  font-family: 'Roboto' !important;
  font-size: 18px;
}

@media (min-width: 600px) and (min-resolution: 96dpi) {
  .font-style-wsname {
    font-family: 'Roboto' !important;
    font-size: 2.55rem;
    font-weight: 700;
    font-style: normal;
    color: #FFFFFF;
    margin-bottom: 0rem !important;
  }
  .font-style-wstitle {
    font-family: 'Roboto' !important;
    font-size: 1.45rem;
    font-weight: 400;
    color:#FFFFFF;
  }
  .font-style-wsdeescription {
    font-family: 'Roboto' !important;
    font-size: 1.31rem;
    font-weight: 400;
    color:#FFFFFF;
  }
  .font-style-wsedit {
    font-family: 'Roboto' !important;
    font-size: 0.88rem;
  }
  .font-style-wslink {
    font-family: 'Roboto' !important;
    font-size: 2.55rem;
  }
  .font-style-wsbutton {
    font-family: 'Roboto' !important;
    font-size: 1.2rem;
  }
  .font-style-wsapptitle {
    font-family: 'Roboto' !important;
    font-size: 2.55rem;
  }
  .font-style-wscategdesc {
    font-family: 'Roboto' !important;
    font-size: 1.3rem;
  }
  .font-style-wsappdesc {
    font-family: 'Roboto' !important;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.005em;
    color: #0B4566;
  }
  .font-style-wsiconup {
    font-family: 'Roboto';
    font-size: 3rem;
  }
  .font-style-bottomtext {
    font-family: 'Roboto' !important;
    font-size: 1rem;
  }
}

@media (min-width: 600px) and (min-resolution: 120dpi) {
  .font-style-wsname {
    font-family: 'Roboto' !important;
    font-size: 1.95rem;
    font-weight: 700;
    color: #FFFFFF;
    margin-bottom: 0rem !important;
  }
  .font-style-wstitle {
    font-family: 'Roboto';
    font-size: 1.1rem;
    font-weight: 400;
    color: #FFFFFF;
  }
  .font-style-wsdeescription {
    font-family: 'Roboto' !important;
    font-size: 1.02rem;
    font-weight: 400;
    color: #FFFFFF;
  }
  .font-style-wsedit {
    font-family: 'Roboto' !important;
    font-size: 0.80rem;
  }
  .font-style-wslink {
    font-family: 'Roboto' !important;
    font-size: 1.95rem;
  }
  .font-style-wsbutton {
    font-family: 'Roboto' !important;
    font-size: 0.9rem;
  }
  .font-style-wsapptitle {
    font-family: 'Roboto' !important;
    font-size: 1.95rem;
  }
  .font-style-wscategdesc {
    font-family: 'Roboto' !important;
    font-size: 1.1rem;
  }
  .font-style-wsappdesc {
    font-family: 'Roboto' !important;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.005em;
    color: #0B4566;
  }
  .font-style-wsiconup {
    font-family: 'Roboto';
    font-size: 2.3rem;
  }
  .font-style-bottomtext {
    font-family: 'Roboto' !important;
    font-size: 0.9rem;
  }
}

@media (min-width: 600px) and (min-resolution: 140dpi) {
  .font-style-wsname {
    font-family: 'Roboto' !important;
    font-size: 1.6rem;
    font-weight: bold !important;
    color: #FFFFFF;
    margin-bottom: 0rem !important;
  }
  .font-style-wstitle {
    font-family: 'Roboto' !important;
    font-size: 0.9rem;
    color: #FFFFFF;
  }
  .font-style-wsdeescription {
    font-family: 'Roboto' !important;
    font-size: 0.84rem;
    color: #FFFFFF;
  }
  .font-style-wsedit {
    font-family: 'Roboto' !important;
    font-size: 0.72rem;
  }
  .font-style-wslink {
    font-family: 'Roboto' !important;
    font-size: 1.6rem;
  }
  .font-style-wsbutton {
    font-family: 'Roboto' !important;
    font-size: 0.8rem;
  }
  .font-style-wsapptitle {
    font-family: 'Roboto' !important;
    font-size: 1.6rem;
  }
  .font-style-wscategdesc {
    font-family: 'Roboto' !important;
    font-size: 0.9rem;
  }
  .font-style-wsappdesc {
    font-family: 'Roboto' !important;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.005em;
    color: #0B4566;
  }
  .font-style-wsiconup {
    font-family: 'Roboto';
    font-size: 1.6rem;
  }
  .font-style-bottomtext {
    font-family: 'Roboto' !important;
    font-size: 0.75rem;
  }
}

@media (min-width: 600px) and (min-resolution: 150dpi) {
  .font-style-wsname {
    font-family: 'Roboto' !important;
    font-size: 1.33rem;
    font-weight: bold !important;
    color: #FFFFFF;
    margin-bottom: 0rem !important;
  }
  .font-style-wstitle {
    font-family: 'Roboto' !important;
    font-size: 0.75rem;
    color: #FFFFFF;
  }
  .font-style-wsdeescription {
    font-family: 'Roboto' !important;
    font-size: 0.70rem;
    color: #FFFFFF;
  }
  .font-style-wsedit {
    font-family: 'Roboto' !important;
    font-size: 0.64rem;
  }
  .font-style-wslink {
    font-family: 'Roboto' !important;
    font-size: 1.33rem;
  }
  .font-style-wsbutton {
    font-family: 'Roboto' !important;
    font-size: 0.7rem;
  }
  .font-style-wsapptitle {
    font-family: 'Roboto' !important;
    font-size: 1.33rem;
  }
  .font-style-wscategdesc {
    font-family: 'Roboto' !important;
    font-size: 0.75rem;
  }
  .font-style-wsappdesc {
    font-family: 'Roboto' !important;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.005em;
    color: #0B4566;
  }
  .font-style-wsiconup {
    font-family: 'Roboto';
    font-size: 1.4rem;
  }
  .font-style-bottomtext {
    font-family: 'Roboto' !important;
    font-size: 0.65rem;
  }
}

@media (max-width: 600px) and (zoom: 100%){
  .font-style-wsname {
    font-family: 'Roboto' !important;
    color: #FFFFFF;
  }
  .font-style-wstitle {
    font-family: 'Roboto' !important;
    color:#FFFFFF;
  }
  .font-style-wsdeescription {
    font-family: 'Roboto' !important;
    color:#FFFFFF;
  }
}

.row-app-landing {
  width: 1525px;
}

.font-style-application_name {
  font-weight: bold;
  font-size: 15px;
}

.font-style-application_desc {
  font-size: 12px;
}

.button-app-height {
  height: 220px !important;
}

.app-div-margin {
  margin-bottom: 15px;
}

.logo-background-transparent {
  background-color: transparent !important;
}

.crop-logo_landing {
  width: 10%;
  min-width: 69px;
  padding-left: 10px;
}

.profileHome {
  right: 135px;
}

.left-logo-landing {
  position: absolute;
  top: 0px;
  margin-top: 3px;
}

.left-logo-width {
  position: absolute;
  width: 60px;
  top: 5px;
  padding: 5px;
}

.border-mask {
  position: absolute;
  width: 100%;
  height: 5px;
  top: 43px;
  left: 0px;
  background: #DBE4E9;
}

.mask {
  position: absolute;
  width: 100%;
  height: 320px;
  top: 48px;
  left: 0px;
  background: rgba(0, 43, 76, 0.5);
}

.category-mask {
  position: absolute;
  width: 100%;
  left: 0px;
  top: 48px;
  opacity: 0.95;
}

.maskCategory {
  background: rgba(0, 43, 76, 0.5);
}

.mask1Category {
  background: linear-gradient(107.76deg, #FFFFFF 67.31%, rgba(255, 255, 255, 0) 98.33%);
  opacity: 0.8;
}

.mask2Category {
  background: linear-gradient(110.96deg, #0B4566 63.77%, rgba(255, 255, 255, 0) 98.21%);
  opacity: 0.8;
}

.mask3Category {
 background: linear-gradient(100.34deg, #FFFFFF 74.79%, rgba(255, 255, 255, 0) 98.63%);
 opacity: 0.8;
}

.row-head-landing {
  position: absolute;
  top: 0px;
  width: 100%;
}

.btn-available {
  color: #8891be;
}

.btn-available:hover {
  color: #8891be;
}

.btn-edit {
  border: 2px solid #8891be;
  border-radius: 50px;
  color: #8891be;
  font-family: "robotoBold";
}

.btn-edit:hover {
  color: #8891be;
}

.btn-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-container {
  width: 90px;
  height: 50px;
  background-color: #FFFFFF;
  border: 1px solid #0B4566;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 18px;
  font-style: normal;
  line-height: 20px;
  text-align: center;
  color: #0B4566;
  white-space: nowrap;
}

.btn-container:hover {
  border-color: #01A4EC;
  background-color: #D8FFF9;
  color: #01A4EC!important;
}

.icon-home-workspace-landing {
  cursor: pointer;
}

.bottom-center-left {
  float: left;
  text-align: left;
  height: 200px;
}

.bottom-center-right {
  float: right;
  text-align: right;
  height: 200px;
}

.bottom-text {
  color: #FFFFFF !important;
  font-weight: bold;
  font-family: sans-serif;
}

.bottom-logo_landing {
  width: 20%;
}

.ws-list-pos {
  position: absolute;
  right: 182px;
  top: 1px;
}

.ws-login-pos {
  position: absolute;
  right: 25px;
  top: 5px;
}

.ws-logout-pos {
  position: absolute;
  right: 80px;
  top: 7px;
  z-index: 99;
}

.ws-edit-pos {
  position: absolute;
  right: 120px;
  top: 5px;
}

.ws-register-pos {
  position: absolute;
  right: 70px;
  top: 5px;
}

.ws-profile-pos {
  position: absolute;
  right: 27px;
  top: 6px;
}

.ws-profile-image-pos {
  position: absolute;
  right: 42px;
  top: 6px;
}

.icon-ws-list-landing {
  font-size: 25px !important;
}

.icon-ws-login-landing {
  font-size: 20px !important;
  font-weight: bold;
}

.icon-ws-profile-landing {
  font-size: 12px !important;
}

.margin-auto {
  margin: auto;
}

.text-color-a {
  color: #0b4769 !important;
  font-weight: bold;
  font-family: sans-serif;
  font-size: 15.5pt;
}

.text-color-b {
  color: #ebecf0 !important;
  font-weight: bold;
  font-family: sans-serif;
  font-size: 15.5pt;
}

.text-color-description {
  color: #ffffff !important;
  font-weight: bold;
  font-family: sans-serif;
  font-size: 15.5pt;
}

/* Workspace app landing Page*/

.left-logo-width_app {
  position: absolute;
  width: 50px;
  top: 8px;
  left: 15px;
}

.left-logo-width_app_ws {
  position: absolute;
  width: 50px;
  top: 3px;
  left: 110px;
}

.ws-profile-pos_app {
  position: absolute;
  right: 25px;
  top: 4px;
}

.ws-logout-pos_app {
  position: absolute;
  right: 80px;
  top: 3px;
}

.ws-home-pos_app {
  position: absolute;
  right: 120px;
  top: 4px;
}

.ws-name-pos-app {
  position: absolute;
  left: 170px;
  top: 13px;
  font-family: sans-serif;
  color: #7e9cae;
  font-weight: bold;
}

.apps-name-pos {
  position: absolute;
  left: 670px;
  top: 11px;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: #2d7198;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus, 
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover, 
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill::first-line {
    font-family: 'Arial';
    font-size: 16px;
}

#portal-scrollbar::-webkit-scrollbar {
    width: 10px;
}

#portal-scrollbar1::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #313547;
}

#portal-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: calc(30% - 5px);
    background: #C1C1C1;
}

#portal-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #C1C1C1;
}

.text-password {
    -webkit-text-security: disc;
    text-security: disc;
}